import { Component } from '@angular/core';
import Data from './data/projects';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  stuff = 'STUFF';
  stuffOpacity = 1;

  projects = Data.projects;

  constructor() {
    setInterval(() => {
      this.stuffOpacity = 0;
      setTimeout(() => {
        this.stuffOpacity = 1;
        let newStuff: string;
        do {
          newStuff = Data.stuff[Math.floor(Math.random() * Data.stuff.length)];
        }while (this.stuff === newStuff);
        this.stuff = newStuff;
      }, 1000);
    }, 4000);
  }

  getExperienceTitle(start: number, end: number, name: string): string {
    return start + ' - ' + end + '  |  ' + '<b>' + name + '</b>';
  }
}
