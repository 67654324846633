import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() media: string;
  @Input() media_name: string;
  @Input() imgPath: string;
  @Input() date: string;
}
