<header class="dark">
  <h1>Thomas MERLE</h1>
  <h2>I love to make<br><span [style.opacity]="stuffOpacity">{{stuff}}</span></h2>
</header>

<section id="intro" class="light">
  <p>I'm a computer scientist graduated from <a href="https://www.esiea.fr/" target="_blank">ESIEA, a graduate school of engineering</a>. I’m currently working at Enerfox a company tackling energy monitoring and optimisation.</p>
  <!-- <p>I search a company where i will be able to use my skills, have challenging missions and most importantly have an impact.</p> -->
  <p>I value environments where I can apply my skills, take on challenging projects, and make a meaningful impact.</p>
</section>

<section id="experiences" class="dark">
  <h2>Professional experiences</h2>
  <div class="stick"></div>
  <article>
    <h4 [innerHTML]="getExperienceTitle(2022, 2024, 'Enerfox')"></h4>
    <i>At Enerfox, <b>I've progressed from fullstack developer to lead developer</b>, spearheading multiple web apps in the energy optimization sector. I've driven significant improvements in developer experience, implementing a monorepo structure, CI/CD pipelines, Docker integration, TypeScript adoption, and staging environments.</i>
    <i>As team lead, <b>I've established robust workflows</b> including code reviews, integration testing, and a service desk for user feedback. I've also introduced release notes and regular one-on-ones to enhance team communication.</i>
    <i><b>A major achievement has been managing the deep re-architecture of Enerfox's SaaS solution</b>, transitioning from InfluxDB to MongoDB and from Express to NestJS, embracing Domain-Driven Development. This project showcases my ability to lead complex technical transitions while maintaining team productivity and product quality.</i>
  </article>
  <article>
    <h4 [innerHTML]="getExperienceTitle(2018, 2021, 'Lactalis')"></h4>
    <i>I’ve worked as a <b>fullstack developer on multiple internal web platforms</b> based on Angular 8 and Springboot.</i>
    <i>As part of a <b>6-month engineering research project</b> at Lactalis, I developed proof-of-concept machine learning solutions, including a deep learning-based push notification system, an LSTM model for workflow prediction, and an anomaly detection system.</i>
    <br><a href="/document/memoire.pdf">Report</a>
  </article>
  <article>
    <h4 [innerHTML]="getExperienceTitle(2017, 2018, 'Isore Batiment')"></h4>
    <!--<i>My main mission was to build a web app to collect man hours, track construction sites progress, make payroll and data visualization for the director board. I've build a NAS on freebsd to help the company manage their internal documents.</i> -->
    <i>My main mission was to build in autonomy a <b>web app used everyday as key element of the payroll system.</b> It target multiple type of user and around 30 users:
      <br>The app was used to collect and analyse man hours, track construction sites progress (for around 10 project per year), make payroll and presence for more then 100 employee and data visualization for the director board. <b>The biggest challenge was to understand the needs of every type of user and develop adequately</b></i>
    <br><i>I've also build a NAS on freebsd to help the company manage their internal documents.</i>
    <br><a href="/document/isore.pdf">Report</a>
  </article>
  <article>
    <h4 [innerHTML]="getExperienceTitle(2016, 2017, 'Mivoc Systèmes')"></h4>
    <i>Mivoc Systèmes needed to update there database supervision solution for better performances, NodeJS was a logical solution with its event-driven architecture. <b>The new system was able to monitor more than 100 BDD over ~10 client and 10 time faster than the previous solution.</b>
      <br>I’ve also build a mobile app on Ionic for the tourism office of Quimper to help tourists find lodging, activities and restaurants.</i>
    <br><a href="/document/mivoc.pdf">Report</a>
  </article>
</section>

<section id="projects" class="light">
  <h2>Personnal projects</h2>
  <div class="stick"></div>
  <app-project *ngFor="let project of projects" [content]="project.content" [media]="project.media" [media_name]="project.media_name" [imgPath]="project.imgPath" [title]="project.title"  [date]="project.date"></app-project>
</section>
