class Data {

  public static stuff: string[] = [
    'STUFF', 'CNC', 'PCB', 'AI', 'RC VEHICULE', 'FPV DRONE', 'ROCKET', 'WEB SITE', 'WEB APP', 'NAS', '3D PRINT', 'CUSTOM PC', 'CODE', 'GRAPHIC DESIGN', 'VIDEO EDITING', 'WOODDEN FURNITURE'
  ];

  public static projects: any[] = [
    {
      title: 'Peek art',
      content: 'Peek is a collaborative venture with my girlfriend, featuring our original embroidered designs on t-shirts. We built a custom website to showcase and sell our creations, while partnering with a specialized embroidery service for production. This project blends our artistic vision with hands-on experience in e-commerce and web development.',
      media: 'https://peek-art.fr',
      media_name: '',
      imgPath: '/assets/peek.png',
      date: '2023-2024'
    }, {
      title: 'Big CNC',
      content: 'After experimenting with a smaller 3D printed CNC, I decided to double down and design my own 1m x 1m CNC machine from scratch. This time, every component choice and design decision was entirely mine. The project challenged me to tackle issues like maintaining rigidity in a large frame and managing electrical noise in long cables. By overcoming these hurdles, I\'ve significantly deepened my understanding of CNC technology. This larger, custom-designed machine represents a major leap in scale, complexity, and personal engineering achievement, opening doors to more ambitious projects',
      media: 'https://peek-art.fr',
      media_name: '',
      imgPath: '/assets/cncBig.jpg',
      date: '2024'
    }, {
      title: 'Drone FPV',
      content: 'I’ve build multiple drones, most of them being FPV drones with real time video transmission. I love to select components, optimise and build the drone as well as flying it. I’ve participated to the french championship of FPV in 2018.',
      media: 'https://www.youtube.com/watch?v=RfhjGu5tlx8',
      media_name: '',
      imgPath: '/assets/fpv.png',
      date: '2016 - 2024'
    }, {
      title: 'Raytracing project',
      content: 'This project in C++ was conducted with an other student, we built a small raytracing engine, the only external library used was "FreeImage" to set pixel colors. The engine is capable of rendering spheres and triangles, import STL files and generate animation.',
      media: '',
      media_name: '',
      imgPath: '/assets/raytracing.gif',
      date: '2020'
    }, {
      title: 'Autonomous drone',
      content: 'I\'ve worked with 2 other students to build an autonomous drone for the competition "DroneLoad", the goal was to build a drone capable of finding target, grabbing cube and mapping his environment. I\'ve mainly worked on the target detection and tracking challenge, I couldn\'t use computationally demanding algorithm like YOLO because we wanted our drone to make everything onboard. I\'ve used simple but effective technique to find and track objects with openCV on python.',
      media: '',
      media_name: '',
      imgPath: '/assets/drone.png',
      date: '2021'
    }, {
      title: 'Ai and radio astronomy',
      content: 'I had a 4 weeks summer school in July 2019 at the Amsterdam University of Applied Sciences. During one week we worked on a project mixing astronomy and AI in a team of 4 students from abroad. I\'ve built and optimised a small neural network with a kaggel dataset about exoplanet.',
      media: '/document/summer_shool.pdf',
      media_name: 'Project report',
      imgPath: '/assets/auas.png',
      date: '2019'
    }, {
      title: 'Image classification',
      content: 'The goal of this challenge was to get the best score at an image classification problem where the classification criteria wasn\'t given. I\'ve  built multiple ANN for this with différent architectures (CNN, ResNet, DenseNet). I\'ve also explored multiple preprocessing and data augmentation techniques.',
      media: '/document/classification.pdf',
      media_name: 'Project report',
      imgPath: '/assets/imageClassification.png',
      date: '2020'
    },  {
      title: 'Cyberattack detection from network packet with an autoencoder',
      content: 'As part of a school project we had to build an autoencoder capable of classifying network packet as normal or abnormal, the idea is that abnormal packet have high risk of being a cyberattack. This neural network used embedding layer for string input, different loss and dense layer.',
      media: '',
      media_name: '',
      imgPath: '/assets/ae.png',
      date: '2020'
    }, {
      title: '3D printed milling CNC',
      content: 'I was really obssessed with CNC but the entry price tag was too high, so i decided to build my own. It is mostly 3D printed, simple 3 axes CNC, aluminium capable. The original design is not mine but i’ve improved it with automatic leveling for PCB milling. This project also taught me CAM and it\'s was harder than I thought!',
      media: 'https://www.youtube.com/watch?v=zLfAGwry8J8',
      media_name: '',
      imgPath: '/assets/cnc.jpg',
      date: '2020'
    }, {
      title: 'Micro rocket',
      content: '[Work in progress] Being passionate by space exploration I was obligated to build micro rocket. The body is entierly 3D printed in 3 parts, the fairing is ejected thanks to springs and a servo motor. Every stage of the flight is controlled by en ESP-8266 combined with an IMU and a pressure sensor. The launch pad uses the same microcontroller to ignite the solid rocket booster that propels the vehicle.',
      media: '',
      media_name: '',
      imgPath: '/assets/microRocket.png',
      date: '2020 - 2021'
    }, {
      title: 'Mini rocket',
      content: '[Work in progress] The association «Planète Sciences» organized a launch campagne every year for experimental and mini rocket. With an other student of my university we are building a mini rocket and aim to launch in 2022 summer after a scub in 2021 due to our main onboard computer malfunctioning. Everything is custom made, glassfiber body, chute, ejection system, aluminium thrust ring, aluminium fin, 3D printed fairing.',
      media: '/document/rocket.png',
      media_name: 'Diagram rocket',
      imgPath: '/assets/miniRocket.png',
      date: '2019 - 2021'
    }, {
      title: '3D printed Car',
      content: 'My first multi part project in 3D printing, i\'ve done all the CAD my self with SolidWorks. Two old drone motors controls independently each rear wheel and one servo motor controls the direction. The only non 3d printed parts are electronics and screw. The tires are in flexible filament.',
      media: '',
      media_name: '',
      imgPath: '/assets/carV1_render.png',
      date: '2018'
    }, {
      title: '3D printed Car V2',
      content: 'I wanted to build a better, bigger 3D printed car. In this design rear and front wheel serves as direction, like the first version only electronic and screw are not 3D printed. This car have enough space for a raspberry, the plan was to add some autonomous control with computer vision algorithm but the project was put on hold before that.',
      media: '',
      media_name: '',
      imgPath: '/assets/carV2_render.png',
      date: '2019'
    }, {
      title: 'Live RC Car control over Twitch API',
      content: '[Work in progress] With my girlfriend we have build a little RC tank with an ESP8266 and 2 modified servo motor for propulsion. The goal is to allow twitch viewers to control the vehicle on a track through a live stream.',
      media: '',
      media_name: '',
      imgPath: '/assets/twitchCar.png',
      date: '2021'
    }, {
      title: 'Blokus',
      content: 'The goal of this project was to recreate the board game "Blokus" in Java. With another student we developed all the functionnalities of the game, a UI, a LAN mode, AI with different levels, customisable pieces and playable from 2 to 4 players.',
      media: '',
      media_name: '',
      imgPath: '/assets/blokus.png',
      date: '2016'
    }, {
      title: 'Torii',
      content: 'Torii aimed to automate door opening for hygiene purpose in hospital. Me and a friend developed a website for right attribution (php / js) and a mobile app to control the doors (cordova) and the embedding system was based on an Arduino Yùn with wifi and bluetooth capabilities. The door could open automatically with bluetooth and a presence sensor or with the app directly but only if the person had the appropriate rights. This was an high school project for the competition "Olympiade des sciences de l\'ingénieur".',
      media: 'https://www.youtube.com/watch?v=OemZ11dXhxQ',
      media_name: '',
      imgPath: '/assets/torii.png',
      date: '2015'
    }
  ];

}

export default Data;
